import { type DropdownMenuOption } from '@air/primitive-dropdown-menu';
import produce from 'immer';
import { useCallback } from 'react';

import { GetSelectionMenuOptionsType } from '~/components/Gallery/types';
import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import {
  selectedBoardsIdsSelector,
  selectedBoardsSelector,
  selectedClipIdsSelector,
  selectedClipItemsSelector,
} from '~/store/selectedItems/selectors';
import { getDropdownMenuOption } from '~/utils/menuOptions/getDropdownMenuOption';
import { canChangeBoardCustomFields } from '~/utils/permissions/boardPermissions';
import { canGenerateZip } from '~/utils/permissions/taskPermissions';
import { useAirStore } from '~/utils/ReduxUtils';

import { useDownloadPublicItems } from './useDownloadPublicItems';
import { useGetPublicCustomFieldsMenuOptions } from './useGetCustomFieldsMenuOptions';

export const usePublicSelectionMenuOptions = () => {
  const { permissions } = usePublicPermissions();
  const { getCustomFieldsMenuOptions } = useGetPublicCustomFieldsMenuOptions();

  const { downloadPublicItems } = useDownloadPublicItems();
  const store = useAirStore();

  const canDownload = canGenerateZip(permissions);
  const canEditCustomFields = canChangeBoardCustomFields(permissions);

  const getSelectionMenuOptions: GetSelectionMenuOptionsType = useCallback(
    (trackLocation) => {
      const selectedClips = selectedClipItemsSelector(store.getState());
      const selectedClipIds = selectedClipIdsSelector(store.getState());
      const selectedBoards = selectedBoardsSelector(store.getState());
      const selectedBoardsIds = selectedBoardsIdsSelector(store.getState());

      const options = produce<DropdownMenuOption[]>([], (draft) => {
        if (canDownload) {
          draft.push({
            ...getDropdownMenuOption('download'),
            onSelect: () => {
              downloadPublicItems({ clipIds: selectedClipIds, boardIds: selectedBoardsIds, trackLocation });
            },
            type: 'item',
          });
        }

        if (canEditCustomFields) {
          const customFieldsMenuOptions = getCustomFieldsMenuOptions({
            boards: selectedBoards,
            assets: selectedClips.map((s) => s.item),
          });
          if (customFieldsMenuOptions.length) {
            draft.splice(3, 0, {
              ...getDropdownMenuOption('edit-custom-fields'),
              options: customFieldsMenuOptions,
              type: 'sub',
            });
          }
        }

        return draft;
      });

      return options;
    },
    [store, canDownload, canEditCustomFields, downloadPublicItems, getCustomFieldsMenuOptions],
  );

  return {
    getSelectionMenuOptions,
  };
};

import { Box, BoxProps } from '@air/zephyr';
import { forwardRef } from 'react';

type InfiniteScrollContainerProps = BoxProps;
export const InfiniteScrollContainer = forwardRef(
  ({ tx, children, ...boxProps }: InfiniteScrollContainerProps, ref: BoxProps['ref']) => {
    return (
      <Box
        tx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: 100, ...tx }}
        {...boxProps}
        ref={ref}
      >
        {children}
      </Box>
    );
  },
);
InfiniteScrollContainer.displayName = 'InfiniteScrollContainer';

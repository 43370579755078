import { PublicClip } from '@air/api';
import { Clip } from '@air/api/types';
import { CSSProperties, memo } from 'react';

import { AssetGalleryCard, AssetGalleryCardProps } from '~/components/Gallery/AssetGalleryCard/AssetGalleryCard';
import { KANBAN_ITEM_CARD_WIDTH } from '~/components/KanbanView/shared/kanbanConstants';
import { DndSortableKanbanItemData } from '~/types/DndKit';

import { DndSortableKanbanItem } from './DndSortableKanbanItem';

interface KanbanAssetComponentProps<C> extends Omit<AssetGalleryCardProps<C>, 'cardWidth'> {
  dndDisabled?: boolean;
  kanbanDndItemData: DndSortableKanbanItemData;
  style: CSSProperties;
}
const _KanbanAssetComponent = <C extends Clip | PublicClip>({
  cardHeight,
  dndDisabled,
  kanbanDndItemData,
  style,
  ...rest
}: KanbanAssetComponentProps<C>) => {
  return (
    <DndSortableKanbanItem
      style={style}
      id={kanbanDndItemData.sortableKanbanItemKey}
      data={kanbanDndItemData}
      dndDisabled={dndDisabled}
    >
      <div className="relative" style={{ height: cardHeight }}>
        <AssetGalleryCard
          cardWidth={KANBAN_ITEM_CARD_WIDTH}
          cardHeight={cardHeight}
          getButtonClassName={() =>
            'bg-grey-1 dark:border dark:border-grey-4 shadow-md cursor-pointer shadow-[0_1px_8px_rgba(0,0,0,0.1),0_0_2px_rgba(0,0,0,0.15)]'
          }
          {...rest}
        />
      </div>
    </DndSortableKanbanItem>
  );
};

export const KanbanAssetComponent = memo(_KanbanAssetComponent) as typeof _KanbanAssetComponent;

import { TXProp } from '@air/zephyr';

import Spinner from '../UI/Spinner';
import { InfiniteScrollContainer } from './InfiniteScrollContainer';

interface InfiniteScrollSpinnerProps {
  tx?: TXProp;
  dataTestId?: string;
}

export const InfiniteScrollSpinner = ({ tx, dataTestId }: InfiniteScrollSpinnerProps) => {
  return (
    <InfiniteScrollContainer tx={tx}>
      <Spinner dataTestId={dataTestId} />
    </InfiniteScrollContainer>
  );
};

import { BoxProps } from '@air/zephyr';
import { memo } from 'react';

import { ContentHeaderDescriptionContainer } from '~/components/PageHeader/ContentHeaderDescriptionContainer';
import { EditableContentHeaderDescription } from '~/components/PageHeader/EditableContentHeaderDescription';
import { BOARD_BLURB_DESCRIPTION } from '~/constants/testIDs';

interface DescriptionProps extends Pick<BoxProps, 'tx'> {
  description: string;
  onSubmit?: (description: string) => void;
}

export const BoardHeaderDescription = memo(({ description, onSubmit }: DescriptionProps) => {
  return (
    <ContentHeaderDescriptionContainer>
      <EditableContentHeaderDescription
        label="Board description"
        text={description}
        onSubmit={onSubmit}
        testId={BOARD_BLURB_DESCRIPTION}
      />
    </ContentHeaderDescriptionContainer>
  );
});

BoardHeaderDescription.displayName = 'BoardHeaderDescription';

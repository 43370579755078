import { AirActionTrackingLocation, useTrackCopiedShareLink } from '@air/analytics';
import { ShortUrlObjectType } from '@air/api/types';
import { useShortIdContext } from '@air/provider-short-id';
import { Modal } from '@air/zephyr';

import { NEW_TAB } from '~/constants/urls';

import { CopyField } from '../../UI/CopyField';

export interface SharePublicItemModalProps extends AirModalProps {
  type: ShortUrlObjectType.clip | ShortUrlObjectType.board;
  id: string;
  url: string;
  trackLocation: AirActionTrackingLocation;
}

export const SharePublicItemModal = ({ trackLocation, id, type, url, onClose, ...rest }: SharePublicItemModalProps) => {
  const { trackCopiedShareLink } = useTrackCopiedShareLink();
  const { shortId } = useShortIdContext();

  return (
    <Modal modalLabel="Share a link" onDismiss={onClose} isAlertModal={false} {...rest}>
      <CopyField
        url={url}
        onCopy={() => {
          trackCopiedShareLink({
            id,
            shareId: shortId,
            type,
            location: trackLocation,
          });
        }}
        menuActions={[
          {
            text: 'Preview link',
            action: () => {
              window.open(url, NEW_TAB);
            },
          },
        ]}
      />
    </Modal>
  );
};
